
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import type Day from '@/modules/common/types/day.type';
import RankingHistoryService, { RankingHistoryServiceS } from '../ranking-history.service';
import PopupDateFilter from '../filters/popup-date.filter';

@Component({
    components: {
        // DayChanger,
    },
    filters: {
        PopupDateFilter,
    },
})
export default class RankingHistoryHeader extends Vue {
    @inject(RankingHistoryServiceS) private rankingHistoryService!: RankingHistoryService;

    get updateDate() {
        return this.rankingHistoryService.updateDate;
    }
}
